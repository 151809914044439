import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import PostTags from "../components/PostTags"
import config from "../../data/SiteConfig"

const PostTemplate = props => {
  const { data, pageContext } = props
  const { slug, date } = pageContext
  const postNode = data.markdownRemark
  const post = postNode.frontmatter
  if (!post.id) {
    post.id = slug
  }
  if (!post.category_id) {
    post.category_id = config.postDefaultCategoryID
  }

  const { id, category_id, title, tags, fontMonth, cover = undefined } = post
  let coverImgFluid
  if (cover) {
    coverImgFluid = cover.childImageSharp.fluid
  }

  return (
    <Layout>
      <div className="post-container">
        <Helmet>
          <title>{`${title} | ${config.siteTitle}`}</title>
        </Helmet>
        <div className="post-content">
          <h1 className={`post-title ${fontMonth}-title`}>{title}</h1>
          {cover && <Img fluid={coverImgFluid} />}

          <div
            className={`${fontMonth}`}
            dangerouslySetInnerHTML={{ __html: postNode.html }}
          />
          <div className="post-meta">
            <PostTags tags={tags} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date
        category
        tags
        cover {
          ...CoverImage
        }
        fontMonth
      }
      fields {
        slug
        date
      }
    }
  }
`

export default PostTemplate
