import React from "react"
import _ from "lodash"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import colors from "../styles/colors"

const tagContainerStyle = css`
  /* border: 1px solid red; */
  height: 30px;
  padding: 3px 0px 3px 0px;
  display: flex;
  flex-direction: row;
`

const tagChip = css`
  background-color: ${colors.secondary};
  color: ${colors.primary};
  margin: 5px 10px 5px 0px;
  padding: 8px 12px 10px 12px;
  height: 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  /* border: 1px solid pink; */
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`

const PostTags = props => {
  const { tags } = props
  return (
    <div css={tagContainerStyle}>
      {tags &&
        tags.map(tag => (
          <Link css={tagChip} key={tag} to={`/tags/${_.kebabCase(tag)}`}>
            {tag}
          </Link>
        ))}
    </div>
  )
}

export default PostTags
